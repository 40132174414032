import { ReactElement, useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { Flex, Heading, Text, VStack, Box, Button } from "@chakra-ui/react";
import { TwoFAForm } from "@components/Forms/TwoFAForm";
import { LoginForm } from "@components/Forms/LoginForm";
import Image from "next/image";
import AuthLayout from "@components/Layouts/AuthLayout";
import useTranslation from "@hooks/useTranslation";
import { TenantThemeContext } from "@contexts/tenantTheme/TenantThemeContext";
import ImageWithFallback from "@components/ImageWithFallback";
import { getSession, signIn } from "next-auth/react";
import { csrf } from "@lib/api/auth";

const Login = () => {
    // TODO: Create a hook for this login logic

    const { logo, setTenantTheme, ...restTenantTheme } = useContext(
        TenantThemeContext
    );

    const [errors, setErrors] = useState([]);

    const [, setStatus] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const [twoFA, setTwoFA] = useState(false);

    const router = useRouter();

    const callbackUrl = router?.query?.callbackUrl as string;

    const { t } = useTranslation("login");

    const login = async values => {
        setIsLoading(true);
        setErrors([]);

        await csrf();

        const response = await signIn("credentials", {
            ...values,
            type: "login",
            redirect: false,
        });

        if (response?.error) {
            setErrors([response?.error]);
        }

        if (!response?.error && response?.ok) {
            const session = await getSession();

            setTenantTheme({
                ...restTenantTheme,
                locale: session?.user?.language,
            });

            if (session?.user?.has2FA) {
                setTwoFA(true);
            } else {
                router.push(callbackUrl || "/dashboard");
            }
        }

        setIsLoading(false);
    };

    const twoFALogin = async values => {
        setIsLoading(true);
        setErrors([]);

        const response = await signIn("credentials", {
            ...values,
            type: "verify",
            redirect: false,
        });

        if (response?.error) {
            setErrors([response?.error]);
        }

        if (!response?.error && response?.ok) {
            router.push(callbackUrl || "/dashboard");
        }

        setIsLoading(false);
    };

    // when 2FA enabled
    useEffect(() => {
        if (router.query.reset?.length > 0 && errors.length === 0) {
            setStatus(router.query.reset);
        } else {
            setStatus(null);
        }
    }, []);

    return (
        <Flex direction="column" align="flex-start" justify="start" w="full">
            <Flex direction="row" align="center">
                {twoFA && (
                    <Button variant="unstyled" onClick={() => setTwoFA(false)}>
                        <Image
                            alt="Go back"
                            src="/login-assets/go-back.svg"
                            width={40}
                            height={40}
                        />
                    </Button>
                )}
                <Box>
                    <ImageWithFallback
                        alt="tenant logo"
                        src={logo}
                        width={260}
                        height={60}
                        priority
                    />
                </Box>
            </Flex>

            {twoFA ? (
                <VStack alignItems="flex-start" pt={66}>
                    <Text fontSize="2xl" fontWeight="bold" color="gray.700">
                        {t("VERIFICATION_CODE")}
                    </Text>
                </VStack>
            ) : (
                <VStack w={"full"} alignItems="flex-start" pt={30}>
                    <Heading
                        mb={-4}
                        display="flex"
                        alignItems="center"
                        fontSize="xl"
                        as="h3"
                        fontWeight="semibold"
                        textAlign="left"
                        letterSpacing={0.5}
                        color="blackAlpha.900"
                    >
                        {t("WELCOME")}
                        <Text ml={3} fontSize="xl">
                            👋
                        </Text>
                    </Heading>
                    <Text
                        fontWeight="semibold"
                        color="blackAlpha.900"
                        letterSpacing={0.5}
                        fontSize={{ base: "2xl", xl: "3xl" }}
                    >
                        {t("SIGN_IN")}
                    </Text>
                </VStack>
            )}

            {twoFA ? (
                <TwoFAForm
                    onSubmit={twoFALogin}
                    isLoading={isLoading}
                    errors={errors}
                />
            ) : (
                <LoginForm
                    onSubmit={login}
                    isLoading={isLoading}
                    errors={errors}
                />
            )}
        </Flex>
    );
};

Login.getLayout = function getLayout(page: ReactElement) {
    return <AuthLayout>{page}</AuthLayout>;
};

export default Login;
